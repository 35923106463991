




























import { useCiService } from '@/lib/Ci';
import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  setup() {
    const termS = useCiService('TermService');

    //#region create term line
    const contentText = ref('');
    const percentText = ref('');

    const create = async () => {
      const content = contentText.value.trim();
      const percent = Number(percentText.value.trim());

      if (!content.length) return;
      if (isNaN(percent)) return;

      termS.addTerm(content, percent);
      contentText.value = '';
      percentText.value = '';
    };
    //#endregion

    const s = {
      contentText,
      percentText,
      create,
    };
    return s;
  },
});
