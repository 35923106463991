















































import {
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  watch,
} from '@vue/composition-api';

export default defineComponent({
  props: {
    value: {
      type: [String, Number, Boolean] as PropType<
        string | number | boolean | null
      >,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    inputClass: {
      type: [String, Array],
      default: () => [],
    },
    labelClass: {
      type: [String, Array],
      default: () => [],
    },
    flashClass: {
      type: String,
      default: () => `bg-opacity-20 bg-yellow-400`,
    },
    initValue: {
      type: Function as PropType<() => any>,
    },
    textarea: {
      type: Boolean,
      default: () => false,
    },
    writeClass: {
      type: [String, Array],
      default: () => [],
    },
    readClass: {
      type: [String, Array],
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const inputEl = ref<HTMLInputElement | HTMLTextAreaElement>();

    const flashing = ref(false);
    let flashOff: any;

    const getInitValue = () => {
      if (props.initValue) return props.initValue();
      return props.value;
    };

    const hasValue = computed(() => {
      if (props.value == void 0) return false;
      if (typeof props.value === 'string' && !props.value.trim().length)
        return false;
      return true;
    });

    //#region edit
    const edit = ref(false);
    const editText = ref<string | number | boolean | null | undefined>();

    const attempEdit = () => {
      if (props.disabled) return;

      editText.value = props.initValue ? props.initValue() : props.value;
      edit.value = true;
      // it use textarea we should call auto resize
      if (props.textarea)
        nextTick(() => {
          if (!!inputEl.value)
            textareaResize(inputEl.value as HTMLTextAreaElement);
        });
    };

    const cancelEdit = () => {
      editText.value = '';
      edit.value = false;
    };

    const submitEdit = (ev?: KeyboardEvent) => {
      // skip when enter with shift key, we allow going down.
      const useShift = ev?.shiftKey ?? false;
      if (useShift) return;

      let _value = editText.value;
      if (typeof _value === 'string') _value = _value.trim();
      const initValue = getInitValue();

      if (_value !== initValue) {
        emit('submit', _value);
        editText.value = '';
        flashing.value = true;
      }

      edit.value = false;
    };
    //#endregion

    watch(edit, (show) => {
      if (show) {
        setTimeout(() => inputEl.value?.focus());
      }
    });

    watch(flashing, (v) => {
      if (!v) return;
      if (flashOff) clearTimeout(flashOff);
      flashOff = setTimeout(() => (flashing.value = false), 300);
    });

    // click outside
    const clicker = (ev: Event) => {
      const target = ev.target as HTMLElement;
      if (inputEl.value !== target) submitEdit();
    };

    onMounted(() => {
      watch(
        edit,
        (show) => {
          setTimeout(() => {
            if (show) document.body.addEventListener('click', clicker);
            else document.body.removeEventListener('click', clicker);
          });
        },
        { immediate: true },
      );
    });
    onBeforeUnmount(() => {
      document.body.removeEventListener('click', clicker);
    });

    // resize
    const textareaResize = (target: HTMLTextAreaElement) => {
      target.style.height = '1px';
      target.style.height = target.scrollHeight + 5 + 'px';
    };

    const s = {
      edit,
      editText,
      attempEdit,
      cancelEdit,
      submitEdit,
      inputEl,
      flashing,
      textareaResize,
      hasValue,
    };
    return s;
  },
});
