

























































import { useCiService } from '@/lib/Ci';
import { FbTimestamp } from '@/lib/FB';
import { TermDoc, TermSnap } from '@/lib/models/TermModel';
import { subscribeTo, utilFmtDate } from '@/lib/Util';
import { computed, defineComponent } from '@vue/composition-api';
import InlineEdit from '../InlineEdit.vue';

export default defineComponent({
  components: { InlineEdit },
  setup() {
    const termS = useCiService('TermService');

    const termList = subscribeTo<TermSnap[]>([], termS.state.snapshots);

    const termDocs = computed(() => {
      return termList.value.map((snap) => {
        const data = snap.data() ?? ({} as TermDoc);
        return {
          id: snap.id,
          content: data.content,
          created_at: utilFmtDate(data.created_at.toDate()),
          percent: data.percent,
        };
      });
    });

    const update = async (id: string, data: Partial<TermDoc>) => {
      return termS.updateTerm(id, data);
    };

    const remove = async (id: string) => {
      termS.removeTerm(id);
    };

    const s = {
      termDocs,
      remove,
      update,
    };
    return s;
  },
});
