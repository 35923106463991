
















import SetupTermCreate from '@/components/setup-term/SetupTermCreate.vue';
import SetupTermList from '@/components/setup-term/SetupTermList.vue';
import { useCiService } from '@/lib/Ci';
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
} from '@vue/composition-api';
import { Subscription } from 'rxjs';

export default defineComponent({
  components: { SetupTermList, SetupTermCreate },
  setup() {
    const termService = useCiService('TermService');

    //#region setup term list
    let sub: Subscription;
    onMounted(() => (sub = termService.listen().subscribe()));
    onBeforeUnmount(() => sub.unsubscribe());
    //#endregion

    const s = {};
    return s;
  },
});
